import config from '../../../server/config';
export const isProduction = getAppConfig()?.IS_PRODUCTION;

export function getAppConfig() {
  if (typeof window === 'undefined') return config();
  return window.__OCF_APP_DATA__.pageData?.config || {};
}

export function getRoutes() {
  if (typeof window === 'undefined') return [];
  return window.__OCF_APP_DATA__.pageData?.routes || [];
}

export function getAppStore() {
  if (typeof window === 'undefined') return {};
  return window.__OCF_APP_DATA__.pageData?.store || {};
}

export const FORMIK_KEYS_TO_REMOVE = ['touched', 'initialTouched', 'initialValue', 'initialError'];

/**
 * @returns path with express style dynamic slugs resolved according to the given params
 */
export function compilePath(path, params) {
  let output = path;

  Object.entries(params).forEach(([slug, value]) => {
    output = output.replace(`:${slug}`, value);
  });

  return output;
}

/**
 * A better way to chain dynamic className properties to elements
 *
 * Usage example:
 *
 * const isTrue = true
 * const isFalse = false
 *
 * const labelClass = getClassName({
 *   ['some-label']: true,
 *   ['is-new-class']: isFalse,
 *   ['is-other-class']: isTrue
 * })
 *
 * console.log(labelClass) // Outputs "some-label is-other-class"
 *
 * @param classObject
 * @returns {string}
 */
export function getClassName(classObject) {
  return Object.entries(classObject)
    .filter(entry => entry?.[1])
    .map(entry => entry?.[0])
    .join(' ');
}

export function convertEurailToParam(codes) {
  if (!codes || codes.length === 0) return '';
  const sortedCodes = codes.sort((a, b) => a.index - b.index);
  let compiledCodes = '';
  let currentId = '';
  for (const code of sortedCodes) {
    if (!code.value) continue;
    if (compiledCodes.length > 0 && currentId !== code.id) compiledCodes += ';';
    if (currentId !== code.id) {
      currentId = code.id;
      compiledCodes += `${code.id},${code.value}`;
    } else {
      compiledCodes += `,${code.value}`;
    }
  }

  return compiledCodes;
}

export function convertFipToParam(codes) {
  if (!codes || codes.length === 0) return '';
  let compiledCodes = '';
  let currentId = '';
  for (const code of codes) {
    if (!code.code) continue;
    if (currentId !== code.index) {
      currentId = code.index;
      compiledCodes += `${code.code},${code.selectValue};`;
    }
  }
  return compiledCodes;
}
