import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isTurnitApp } from './turnit-app';

const DISABLED_INTEGRATIONS = [
  // This is useful to catch exceptions outside of ErrorBoundary
  // But decided to disable it, because most of the exceptions were
  // from external analytic scripts and their invalid querySelectors.
  'TryCatch',
];

const beforeBreadcrumb = (breadcrumb, hint) => {
  if (breadcrumb.category === 'fetch') {
    breadcrumb.data = {
      ...breadcrumb.data,
      'x-request-correlation-key': hint.response.headers.get('X-Request-Correlation-Key'),
    };
  }

  return breadcrumb;
};

const setupIntegrations = integrations => {
  const enabledDefaultIntegrations = integrations.filter(integration => {
    return !DISABLED_INTEGRATIONS.includes(integration.name);
  });

  return [...enabledDefaultIntegrations, new Integrations.BrowserTracing()];
};

export const init = ({
  dsn = null,
  release = null,
  ignoreErrors = [],
  denyUrls = [],

  ...options
}) => {
  if (dsn) {
    Sentry.init({
      dsn,
      release,
      integrations: setupIntegrations,
      autoSessionTracking: false,
      normalizeDepth: 10,

      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Browser thrown error when request is cancelled.
        'ignored_fetch_error',
        'TypeError: Failed to fetch', // Chrome
        'TypeError: NetworkError when attempting to fetch resource.', // Firefox
        'TypeError: cancelled', // iOS
        'TypeError: Load failed',
        'TypeError: The network connection was lost.',
        /Non-Error promise rejection captured with keys:.*/,
        'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        'ChunkLoadError',
        'compose request failed',
        'webkit-masked-url',
        'Object Not Found Matching Id',
        'Loading CSS chunk',
        'jQuery',
        'Rejected',
        'chain is not set up',
        "(evaluating 'a.L')",
        'obtainVideoInfos',
        'secret is not defined',
        'getRestrictions',
        'Cannot redefine property: googletag',
        'redefine non-configurable property "userAgent"',
        "Unexpected identifier 'https'",
        'DataCloneError',
        '__SENTRY_LOADER__',
        "Failed to read the 'localStorage' property from 'Window'",
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        // Adyen checkout web sdk
        'Page already has an active payment session.',
        ...ignoreErrors,
      ],

      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

        // Exponea analytics
        /api\.eu1\.exponea\.com\/js\/exponea\.min\.js/i,
        ...denyUrls,
      ],

      beforeBreadcrumb,

      ...options,
    });

    Sentry.configureScope(scope => {
      scope.setTag('app.url', window.location.host);
      scope.setTag('app.mobile', isTurnitApp());
      if (release) scope.setTag('app.release', release);
    });
  }
};
