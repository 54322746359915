import React from 'react';
import MenuItemComponent from '../menus/MenuItemComponent';
import MobilePopup from '../ui/MobilePopup';
import ChevronIcon from '../../icons/ChevronIcon';

export default function MenuMobilePopupContent({ item, toggle, closeModals }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeChild, setActiveChild] = React.useState(false);

  const onChildClick = elem => {
    if (elem.children && elem.children.length > 0) {
      setIsOpen(true);
      setActiveChild(elem);
    } else {
      closeModals();
    }
  };

  return (
    <ul className="menu-mobile-popup-content">
      <div className="subtitle" onClick={toggle}>
        <ChevronIcon rotate={-90} color="#909597" />
        {item.name}
      </div>

      {item.children
        .filter(c => c?.enabled)
        .map(child => {
          return (
            <li className="header-menu__child" key={child.id}>
              <MenuItemComponent menuItem={child} onClick={() => onChildClick(child)} toWebsite />

              <MobilePopup
                className="popup-header -tertiary"
                header
                isNestedModal
                show={isOpen && activeChild.children && activeChild.children.length > 0}
                toggle={() => setIsOpen(!isOpen)}
                closeModals={closeModals}
                withDivider
              >
                <MenuMobilePopupContent
                  item={activeChild}
                  closeModals={closeModals}
                  toggle={() => setIsOpen(!isOpen)}
                />
              </MobilePopup>
            </li>
          );
        })}
    </ul>
  );
}
