import { action, makeObservable, observable } from 'mobx';
import { Turnit } from 'app/js/helpers/api/requests';

export default class Search {
  @observable theme = {};
  @observable config = {};
  @observable stops = {};
  @observable fareClasses = [];
  @observable eurailCodes = [];
  @observable fipCodes = [];
  defaultOriginStop = null;
  defaultDestinationStop = null;

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action async fetchData() {
    try {
      const { body } = await Turnit.search({
        queryParams: {
          locale: this.rootStore.i18n.locale,
        },
      });

      this.theme = body.Theme;
      this.stops = body.Stops;
      this.fareClasses = body.FareClasses;
      this.config = body.Config;

      // Set default stops
      this.defaultOriginStop = this.config.DefaultOriginCityId
        ? `C_${this.config.DefaultOriginCityId}`
        : this.config.DefaultOriginStopId
        ? `S_${this.config.DefaultOriginStopId}`
        : void 0;

      this.defaultDestinationStop = this.config.DefaultDestinationCityId
        ? `C_${this.config.DefaultDestinationCityId}`
        : this.config.DefaultDestinationStopId
        ? `S_${this.config.DefaultDestinationStopId}`
        : void 0;
      return body;
    } catch (err) {
      this.rootStore.toasts.showErrorToast(this.rootStore.t('errors.internal_error'));
      return null;
    }
  }

  setFareClassAmount = (fareClassId, amount) => {
    const index = this.rootStore.search.fareClasses.findIndex(f => f.Id === fareClassId);
    if (index === -1) {
      console.warn(`No fareclass with ID ${fareClassId}`);
      return;
    }
    this.fareClasses[index].Amount = amount;
  };

  @action setEurailCodes = values => {
    this.eurailCodes = values;
  };

  @action setFipCodes = values => {
    this.fipCodes = values;
  };
}
