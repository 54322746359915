import React, { useEffect, useMemo } from 'react';
import { useSearchProps, convertFareClassesToParam, TurnitSearch } from '@turnit-ride/turnit-search-widget';
import Heading from '../../components/Heading/Heading';
import { Turnit } from '../../../helpers/api/requests';
import { removeTrailingSlash } from '../../../helpers/remove-trailing-space';
import useMobx from '@Shared/hooks/useMobx';
import { goToWebshop } from '../../../helpers/go-to';
import { PromotionCodes } from '@Shared/constants/b2c';
import { observer } from 'mobx-react-lite';
import { convertEurailToParam, convertFipToParam } from 'app/js/helpers/utils';

function Search({ titleColor }) {
  const { content, i18n, t, toasts, search, account } = useMobx();

  useEffect(() => search.fetchData(), []);

  const { setFareClasses, onPromoCodeChange, ...searchProps } = useSearchProps();

  useEffect(async () => {
    if (!account?.travelPasses && account?.isLoggedIn) await account?.getTravelPasses();
  }, [account?.isLoggedIn]);

  const fetchTravelPassByNumber = async number => {
    return await Turnit.getTravelPassByNumber(number);
  };

  const handlePromoCodeChange = async code => {
    if (code) {
      try {
        const { body } = await Turnit.promoCode.validate(code);

        if (body.Code === PromotionCodes.Ok) {
          onPromoCodeChange(code);
          return true;
        }

        toasts.showWarningToast(t('errors.promotion.not_found'));
        return false;
      } catch (err) {
        console.error(err?.body || err);
        toasts.showErrorToast(t('errors.internal_error'));

        return false;
      }
    } else {
      onPromoCodeChange('');
      return true;
    }
  };

  const handleError = ({ message }) => toasts.showErrorToast(message);
  const handleWarning = ({ message }) => toasts.showWarningToast(message);
  const handleInfo = ({ message }) => toasts.showInfoToast(message);

  useEffect(() => {
    if (search.fareClasses) {
      setFareClasses(search.fareClasses);
      search.setFareClassAmount('BONUS_SCHEME_GROUP.ADULT', search.config?.DefaultPassengerCount);
    }
  }, [search.fareClasses]);

  const widgetTranslations = useMemo(
    () => ({
      from: t('views.search.origin'),
      to: t('views.search.destination'),
      departing: t('views.search.choose_departing_date'),
      return: t('views.search.add_return_trip'),
      passengers: t('views.search.class_and_passengers'),
      group_ticket: t('views.search.toggle_group_ticket'),
      see_more: t('views.search.see_more'),
      see_less: t('views.search.see_less'),
      travel_with_bike: t('views.search.travel-with-bike'),
      travel_with_pet: t('views.search.travel-with-pet'),
      travel_with_wheelchair: t('views.search.add_wheelchair_seat'),
      travel_with_baby: t('views.search.travel-with-baby'),
      add_promo_code: t('views.search.addPromoCode'),
      add_promo_code_placeholder: t('views.search.promoCodePlaceholder'),
      add_travel_pass: t('views.search.addTravelpass'),
      add_travel_pass_placeholder: t('views.search.addTravelpassByNumber'),
      search_button: t('views.search.search'),
      confirm_button: t('buttons.confirm'),
      fare_class_passengers: t('views.search.select-passengers'),
      fare_class_accessories: t('views.search.select-travel-with'),
      no_results_found: t('errors.no_results_found'),
      'search_types.travelpass': t('components.search.type.travelpass'),
      'search_types.ticket': t('components.search.type.ticket'),
      'fareclass.wheelchair_passenger_select': t('views.search.passenger-with-wheelchair-statement'),
      family_ticket: t('views.search.toggle_family_ticket'),
      close: t('buttons.close'),
      'group-ticket-max-passengers-reached': t('toast.group-ticket-max-passengers-reached'),
      'max-travelpass-passengers-reached': t('toast.max-travelpass-passengers-reached'),
      'group-ticket-min-passengers-reached': t('toast.group-ticket-min-passengers-reached'),
      'group-ticket-availability-reached': t('views.search.info.group-ticket-min-limit-reached'),
      'errors.search_empty_fields': t('errors.empty_fields'),
      'errors.search_origin_value_invalid': t('errors.search_origin_value_invalid'),
      'errors.search_destination_value_invalid': t('errors.search_destination_value_invalid'),
      switch_journey_direction: t('buttons.switch_journey_direction'),
      clear_return_date: t('buttons.clear_return_date'),
      count: t('views.search.count'),
      'errors.travelpass.not_found': t('views.search.errors.travelpass.not_found'),
      'buttons.increment': t('buttons.increment'),
      'buttons.decrement': t('buttons.decrement'),
      'views.search.read_only_reason.from_field_must_be_filled': t(
        'views.search.read_only_reason.from_field_must_be_filled'
      ),
      selectTravelpass: t('views.search.selectTravelpass'),
      addTravelpassByNumber: t('views.search.addTravelpassByNumber'),
      addTravelpass: t('views.search.addTravelpass'),
      giftCard: t('views.search.giftCard'),
      accept_close: t('buttons.accept_close'),
      cancel_button: t('buttons.cancel'),
      eurail_modal_title: t('eurail.modal_title'),
      eurail_modal_description: t('eurail.modal_description'),
      eurail_modal_disclaimer: t('eurail.modal_disclaimer'),
      eurail_field_error: t('eurail.field_error'),
      eurail_code_placeholder: t('eurail.input_placeholder'),
      eurail_modal_fee_disclaimer: t('eurail.fee_disclaimer'),
      fip_toggle_title: t('fip.toggle_title'),
      fip_modal_title: t('fip.modal_title'),
      fip_modal_description: t('fip.modal_description'),
      fip_input_label: t('fip.input_label'),
      fip_select_validation_error: t('fip.select_validation_error'),
      fip_select_placeholder: t('fip.select_placeholder'),
      fip_validation_error: t('fip.validation_error'),
      fip_discount_100: t('fip.discount_100'),
      fip_discount_50: t('fip.discount_50'),
    }),
    [i18n.locale]
  );

  const submit = (path, baseQueryParams) => {
    if (!content.webshopUrl) return;

    const webshopUrl = removeTrailingSlash(content.webshopUrl);
    const page = `${webshopUrl}/${path}`;
    if (!baseQueryParams) goToWebshop(`${webshopUrl}/${path}`);

    if (baseQueryParams) {
      const queryParams = Object.keys(baseQueryParams)
        .filter(key => baseQueryParams[key] !== undefined && baseQueryParams[key] !== null)
        .map(key => `${key}=${baseQueryParams[key]}`)
        .join('&');

      goToWebshop(`${page}?${queryParams}`);
    }
  };

  const handleTicketSearch = ({
    originStop,
    destinationStop,
    departDate,
    returnDate,
    fareClasses,
    wheelchairFareClass,
    withBike,
    withPet,
    withBaby,
    isGroupTicket,
    promoCode,
    travelPass,
  }) => {
    submit('journeys', {
      oStop: originStop.BusStopId,
      dStop: destinationStop.BusStopId,
      oCity: originStop.CityId,
      dCity: destinationStop.CityId,
      oDate: departDate,
      dDate: returnDate,
      fareClasses: convertFareClassesToParam(fareClasses),
      wheelchairFareClass,
      travelWithBike: withBike,
      travelWithPet: withPet,
      travelWithBaby: withBaby,
      isGroupTicket,
      promoCode,
      travelPass: travelPass?.Number,
      euRailInterRailCodes: convertEurailToParam(search.eurailCodes),
      fipCodes: convertFipToParam(search.fipCodes),
    });
  };

  const handleGiftCardButtonClick = () => {
    submit('gift-card');
  };

  const handleTravelpassSearch = ({ originStop, destinationStop }) => {
    submit('purchase-travelpass', {
      oStop: originStop.BusStopId,
      dStop: destinationStop.BusStopId,
      oCity: originStop.CityId,
      dCity: destinationStop.CityId,
    });
  };

  return (
    <div className="turnit__search">
      <Heading level="h1" visual="h2" color={titleColor}>
        {t(content.translations.search_title)}
      </Heading>

      <TurnitSearch
        colors={{ ...search.theme, layoutFullWidth: search.config.Client === 'ltg' }}
        translations={{
          ...widgetTranslations,
          disabled_group_ticket: t('website.views.search.group-ticket-toggle-disabled-description', [
            search.config?.GroupTicketLimits?.LowerLimit || 3,
            search.config?.GroupTicketLimits?.UpperLimit || 6,
          ]),
        }}
        locale={i18n.locale}
        localeLocale={i18n.locale === 'en' ? 'en-GB' : i18n.locale}
        config={{
          ...search.config,
          TravelPasses: account?.travelPasses,
          IsGiftCardButtonEnabled: search.config?.GiftCard?.IsGiftCardButtonEnabled,
          IsPromoCodeButtonEnabled: search.config?.IsPromoCodeEnabled,
        }}
        stops={search.stops}
        lockType={!(search.config.IsTravelpassEnabled ?? true)}
        onGetTravelPassByNumber={fetchTravelPassByNumber}
        onPromoCodeChange={handlePromoCodeChange}
        onTicketSearch={handleTicketSearch}
        onTravelpassSearch={handleTravelpassSearch}
        onWarning={handleWarning}
        onError={handleError}
        onInfo={handleInfo}
        onEurailCodeChange={search.setEurailCodes}
        onFipCodeChange={search.setFipCodes}
        defaultPassengerCount={search.config?.DefaultPassengerCount || 0}
        onSetFareClassAmount={search.setFareClassAmount}
        defaultOriginStopId={search.defaultOriginStop}
        defaultDestinStopId={search.defaultDestinationStop}
        onGiftCardButtonClick={handleGiftCardButtonClick}
        isGiftCardButtonHidden={!search.config?.GiftCard?.IsGiftCardButtonEnabled}
        isEurailEnabled={search.config?.IsEurailEnabled}
        eurailCodes={search.eurailCodes}
        fipCodes={search.fipCodes}
        IsFipEnabled={search.config.IsFipEnabled}
        hidePromoCodeButton={
          search.config?.IsEurailEnabled
            ? search.eurailCodes?.length && search.eurailCodes?.map(ev => !!ev?.value)?.filter(e => e)?.length
            : false
        }
        {...searchProps}
      />
    </div>
  );
}

export default observer(Search);
