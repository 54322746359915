import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';
import MenuItemComponent from './MenuItemComponent';
import CloseIcon from '../../icons/CloseIcon';
import MaxWidth from '../ui/MaxWidth';
import { useSharedData } from '../../Shared';

function MenuPopdown({ show, menu, onLinkClick, close, ...props }) {
  const previousMenu = React.useRef();
  const node = React.useRef();
  const linksRef = React.useRef();
  const { t } = useSharedData();

  if (menu !== previousMenu.current) previousMenu.current = menu;
  const getMenuItems = _menu => (_menu && _menu.children && _menu.children.filter(m => !!m.enabled)) || [];

  useEffect(() => {
    if (show && linksRef.current) linksRef.current.focus();
  }, [show]);

  return (
    <CSSTransition className="menu-popdown" in={show} classNames="anim" unmountOnExit timeout={300}>
      <div {...props} ref={node}>
        <MaxWidth>
          <ul className="_links-area" ref={linksRef} tabIndex={-1} aria-label={menu?.name}>
            {getMenuItems(menu).map(child => (
              <li key={child.id} className={`menu-item-wrapper${child.children?.length > 0 ? ' has-children' : ''}`}>
                <MenuItemComponent menuItem={child} onClick={() => child.value && onLinkClick()} toWebsite />
                <ul className="menu-item-children">
                  {child.children
                    .filter(ch => ch.enabled)
                    .map((mi, key) => (
                      <li key={key}>
                        <MenuItemComponent key={mi.id} menuItem={mi} onClick={() => onLinkClick()} toWebsite />
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>

          <button onClick={close} className="close-button" aria-label={t('buttons.close')}>
            <CloseIcon large />
          </button>
        </MaxWidth>
      </div>
    </CSSTransition>
  );
}

export default observer(MenuPopdown);
